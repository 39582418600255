<template>
  <div
    class="full no-expand flex-column"
    style="background-color: #f6f6f6; width: 100%"
  >
    <overlay :show="loading" z-index="3000">
      <div class="wrapper">
        <loading type="spinner" size="30" />
      </div>
    </overlay>
    <div v-if="list[currentIndex]" class="flex-row no-expand edit-area">
      <div class="flex-column">
        <div class="user-header">
          <img :src="res.logo" />
        </div>
        <div class="s-openning" v-if="bizStatus">
          <span>营业中</span>
        </div>
        <div class="s-closing" v-if="!bizStatus">
          <span>已打烊</span>
        </div>
      </div>
      <div class="flex-column" style="width: 8.2rem; flex: 1">
        <div>
          <div
            class="van-ellipsis"
            style="
              font-size: 0.7rem;
              font-weight: bold;
              color: #fff;
              padding-top: 0.24rem;
            "
          >
            {{ res.name }}
          </div>
        </div>
        <div
          style="
            display: flex;
            flex: 1;
            align-items: center;
            font-size: 0.6rem;
            color: #e8e8e8;
          "
        >
          祝您生意兴隆
        </div>
        <div
          style="
            margin-top: auto;
            padding: 0.3rem;
            background-color: #fff;
            border-radius: 0.5rem;
            font-size: 0.6rem;
            color: #3a3a3a;
          "
        >
          <van-icon name="completed" /><span style="color: green">
            {{ this.activeTab }}</span
          >/{{ this.totalTab }} &nbsp;&nbsp;<span style="color: red">
            <van-icon name="gold-coin-o" /> {{ this.todayRevenue }}</span
          >
        </div>
      </div>
      <div style="width: auto">
        <van-switch :value="bizStatus" @input="editBizstatus" size="0.8rem" />
      </div>
    </div>
    <div class="navi-container">
      <tabs border v-model="currentIndex">
        <tab
          v-for="c in list"
          :key="c.id"
          :title="c.label"
          :title-style="{ 'font-size': '0.6rem' }"
        />
      </tabs>
    </div>
    <div class="flex-row no-expand" v-if="table.length > 0">
      <scroll ref="bigScroll" style="overflow: paged-y">
        <div style="padding-top: 0.5rem; padding-bottom: 40px">
          <van-grid :gutter="10" :column-num="4" :border="false">
            <van-grid-item
              class="table-grid"
              style="height: 4rem"
              v-for="t in table"
              :key="t.id"
            >
              <div>
                <div @click="checkCurrentOrder(t)">
                  <div style="overflow: hidden; position: relative">
                    <!--                    <div class="tab-number">
                      <div>{{ t.number }}</div>
                    </div>-->
                    <img
                      v-if="t.is_finish"
                      style="width: 2.4rem"
                      src="../../assets/image/table-a.png"
                    />
                    <img
                      v-if="!t.is_finish"
                      style="width: 2.4rem"
                      src="../../assets/image/table-b.png"
                    />
                  </div>
                  <div class="flex-row">
                    <div class="storage">
                      <div class="table-name">
                        {{ t.name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="opt-btn"
                  v-if="isShowBil"
                  @click="checkHistoryOrders(t)"
                >
                  <div class="history-order">历史订单</div>
                </div>
              </div>
            </van-grid-item>
          </van-grid>
        </div>
      </scroll>
    </div>

    <div class="flex-row no-expand show-history" style="padding: 0.2rem">
      <div style="width: 2rem; padding: 0.05rem">
        <van-switch v-model="isShowBil" size="0.8rem" />
      </div>
      <div style="width: 4rem; margin: -0.15rem">
        <span style="font-size: 0.5rem; color: #818790">账单预览</span>
      </div>
    </div>
    <div
      class="flex-row no-expand refresh"
      style="padding: 0.2rem; right: 0.5rem"
    >
      <div style="margin-top: -0.3rem">
        <van-button
          style="flex: 1"
          type="info"
          icon="replay"
          size="mini"
          round
          @click="refreshData"
          >刷新</van-button
        >
      </div>
    </div>
    <van-dialog
      v-model="currentOrderShow"
      title="当前订单"
      :lock-scroll="false"
      width="90vw"
      ><div
        v-if="currentOrder"
        style="height: 70vh; padding: 0.2rem"
        class="no-expand"
      >
        <scroll>
          <div>
            <div class="flex-row">
              <van-button
                style="flex: 1; margin-bottom: 0.1rem"
                type="warning"
                icon="exchange"
                plain
                round
                v-if="currentOrder['pay_status'] != 3"
                size="small"
                :loading="orderLoading"
                @click="showTabPop"
                >换桌</van-button
              >
              <div style="width: 5px"></div>
              <van-button
                style="flex: 1; margin-bottom: 0.1rem"
                type="danger"
                icon="notes-o"
                plain
                round
                size="small"
                :loading="orderLoading"
                @click="printOrder(currentOrder.id)"
                >打票</van-button
              >
              <div style="width: 5px"></div>
              <van-button
                style="flex: 1; margin-bottom: 0.1rem"
                type="info"
                icon="add-o"
                plain
                round
                v-if="currentOrder['pay_status'] != 3"
                size="small"
                :loading="orderLoading"
                @click="showMenuPop"
                >加菜</van-button
              >
              <div style="width: 5px"></div>
              <van-button
                style="flex: 1; margin-bottom: 0.1rem"
                type="danger"
                plain
                icon="close"
                v-if="currentOrder['pay_status'] != 3"
                round
                size="small"
                @click="finishOrder(currentOrder.id)"
                :loading="orderLoading"
                >关桌</van-button
              >
              <div style="width: 5px"></div>
              <van-button
                style="flex: 1; margin-bottom: 0.1rem"
                type="warning"
                icon="after-sale"
                plain
                v-if="currentOrder['pay_status'] != 3"
                round
                size="small"
                @click="showInvoice"
                :loading="orderLoading"
                >结账</van-button
              >
            </div>
            <div
              v-if="currentOrder && currentOrder.unpaidOrderDetails.length > 0"
              class="order-detail flex-column no-expand"
              style="height: 100%"
            >
              <div
                class="order-title flex-row"
                style="font-size: 0.6rem; padding-top: 0.6rem; color: #5e5e5e"
              >
                <van-icon class="common-color" name="bill-o" />
                <span>未支付订单</span>
                <!--                <div style="top: -0.3rem; left: 3rem"><van-switch v-model="isEdit" size="0.8rem" /></div>-->
              </div>
              <div style="padding-top: 0.3rem">
                <table class="my-table" style="font-size: 0.65rem; width: 100%">
                  <thead class="t-head">
                    <tr>
                      <td style="width: 50%">名称</td>
                      <td style="width: 30%">
                        数量<van-switch
                          v-model="isEdit"
                          size="0.6rem"
                          style="top: 0.1rem"
                        />
                      </td>
                      <td style="text-align: right">合计</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="g in currentOrder.unpaidOrderDetails"
                      :key="g.id"
                    >
                      <td>{{ g.goods_name }}</td>
                      <td>
                        <stepper
                          v-if="currentOrder['pay_status'] === 1 && isEdit"
                          theme="round"
                          min="0"
                          integer
                          button-size="20"
                          async-change
                          :disabled="orderLoading"
                          :value="g.total_count"
                          @change="onChange"
                          @blur="changeCount(g)"
                          @plus="changeGoodNum(g, 'plus')"
                          @minus="changeGoodNum(g, 'minus')"
                        />
                        <span v-else>x{{ g.total_count }}</span>
                        <!--                        x{{ g.total_count }}-->
                      </td>
                      <td>¥{{ (g.total_amount * g.total_count) / 100 }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="font-color"
                style="text-align: right; font-size: 0.7rem"
              >
                共<span class="common-color">{{ undoneTotal.count }}</span
                >份菜，总计<span class="common-color">{{
                  undoneTotal.total
                }}</span
                >元
              </div>
            </div>
            <div
              v-if="currentOrder && currentOrder.orderDetails.length > 0"
              class="order-detail flex-column no-expand"
              style="height: 100%"
            >
              <div
                class="order-title flex-row"
                style="font-size: 0.6rem; padding-top: 0.6rem; color: #5e5e5e"
              >
                <van-icon class="common-color" name="bill-o" />
                <span>已支付订单</span>
              </div>
              <div style="padding-top: 0.3rem">
                <table class="my-table" style="font-size: 0.65rem; width: 100%">
                  <thead>
                    <tr>
                      <td style="width: 50%">名称</td>
                      <td style="width: 30%">数量</td>
                      <td style="text-align: right">单价</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="g in currentOrder.orderDetails" :key="g.id">
                      <td>{{ g.goods_name }}</td>
                      <td>x {{ g.total_count }}</td>
                      <td>¥{{ g.total_amount / 100 }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                class="font-color"
                style="text-align: right; font-size: 0.7rem"
              >
                共<span class="common-color">{{ doneTotal.count }}</span
                >份菜，总计<span class="common-color">{{
                  doneTotal.total
                }}</span
                >元
              </div>
            </div>
          </div>
        </scroll>
      </div></van-dialog
    >
    <action-sheet
      title="查看订单"
      :lock-scroll="false"
      v-model="showHistory"
      :style="{ height: '80%' }"
      @opened="$refs.historyScroll.refresh()"
    >
      <div class="full flex-column no-expand">
        <div style="flex: 1" class="no-expand">
          <scroll ref="historyScroll">
            <div>
              <div
                v-for="value in orders"
                :key="value.id"
                class="history-order-item flex-row"
                style="
                  justify-content: space-between;
                  align-items: center;
                  background-color: #fff;
                  margin: 0.3rem;
                  border-radius: 0.3rem;
                "
              >
                <div style="flex: 1">
                  <a
                    @click="checkHistoryOrder(value)"
                    style="
                      color: #1989fa;
                      font-size: 0.66rem;
                      font-weight: bold;
                    "
                    >No.{{ value.print_no || value.id }}</a
                  >
                  <div
                    style="
                      padding-top: 0.4rem;
                      color: #6e6e6e;
                      font-weight: bold;
                    "
                  >
                    ¥ {{ value.pay_amount / 100 }}
                  </div>
                  <div style="padding-top: 0.4rem; color: #9b9b9b">
                    {{ value.update_time }}
                  </div>
                </div>
                <van-button
                  v-if="value.pay_status != 3"
                  type="primary"
                  round
                  size="small"
                  @click="finishOrder(value.id)"
                  >关桌</van-button
                >
                <van-button
                  v-if="value.pay_status === 3"
                  type="info"
                  round
                  size="small"
                  @click="checkHistoryOrder(value)"
                  >详情</van-button
                >
              </div>
            </div>
          </scroll>
        </div>
        <van-pagination
          v-model="currentPage"
          force-ellipses
          :total-items="totalNum"
          :itemsPerPage="pageSize"
          :show-page-size="pageSize"
          @change="checkHistoryOrders"
        />
      </div>
    </action-sheet>
    <van-dialog
      v-model="showDetail"
      title="订单详情"
      show-cancel-button
    ></van-dialog>
    <van-popup
      class="my-popup"
      round
      v-model="showMenu"
      close-icon="close"
      position="right"
      :lock-scroll="false"
      :style="{ width: '85%', height: '100%' }"
      @close="menuClose"
    >
      <div style="height: 100%" class="flex-column no-expand">
        <div>
          <van-field
            v-model="foodName"
            center
            clearable
            placeholder="搜索菜名"
          />
        </div>
        <div>
          <van-field
            v-model="addGoodDetailText"
            center
            type="textarea"
            readonly
            clearable
            placeholder="已选择"
          />
          <div
            style="display: flex; justify-content: flex-end; padding: 0.3rem"
          >
            <van-button
              style="width: 20%; border-radius: 0.5rem"
              type="danger"
              size="mini"
              :loading="addGoodLoding"
              @click="cancelAddGood"
              >取消</van-button
            >&nbsp;
            <van-button
              style="width: 25%; border-radius: 0.5rem"
              type="primary"
              size="mini"
              @click="confirmAddGood(1)"
              :loading="addGoodLoding"
              >下单并打印</van-button
            >&nbsp;
            <van-button
              style="width: 20%; border-radius: 0.5rem"
              type="info"
              size="mini"
              @click="confirmAddGood(0)"
              :loading="addGoodLoding"
              >下单</van-button
            >
          </div>
        </div>
        <div style="flex: 1; overflow: hidden">
          <scroll>
            <div>
              <div v-for="good in searchedGoods" :key="good.id">
                <card
                  :tag="good.status === 2 ? '已下架' : undefined"
                  :price="
                    (good.member_price ? good.member_price : good.price) / 100
                  "
                  :origin-price="
                    good.member_price && good.member_price < good.price
                      ? good.price / 100
                      : undefined
                  "
                  :desc="good.introduce"
                  :title="good.name"
                  :thumb="good.img_url"
                >
                  <template #footer>
                    <stepper
                      theme="round"
                      min="0"
                      button-size="23"
                      async-change
                      :disabled="addGoodLoding"
                      :value="good.count"
                      @plus="addGoodChangeNum(good, 'plus')"
                      @minus="addGoodChangeNum(good, 'minus')"
                    />
                  </template>
                </card>
              </div>
            </div>
          </scroll>
        </div>
      </div>
    </van-popup>
    <van-popup
      class="my-popup"
      round
      v-model="showChangeTab"
      close-icon="close"
      position="left"
      :lock-scroll="false"
      :style="{ width: '80%', height: '100%' }"
    >
      <div style="height: 100%" class="flex-column no-expand">
        <div>
          <van-field
            v-model="tabName"
            center
            clearable
            placeholder="请输入桌台名称"
          />
        </div>
        <div style="flex: 1; overflow: hidden">
          <scroll>
            <div>
              <div v-for="tab in searchedTabs" :key="tab.id">
                <card
                  v-if="!tab.is_finish && !tab.table_mode"
                  :tag="tab.status === 0 ? '空闲中' : undefined"
                  :title="tab.name"
                  :num="tab.number"
                  thumb="http://qiniu.sinemtu.com/sdb-base_table-b.png"
                >
                  <template #footer>
                    <van-button
                      @click="changeTab(tab)"
                      type="info"
                      size="mini"
                      icon="exchange"
                      round
                    />
                  </template>
                </card>
              </div>
            </div>
          </scroll>
        </div>
      </div>
    </van-popup>

    <van-dialog
      v-model="invoicing"
      title="结账"
      show-cancel-button
      :before-close="beforeChange"
    >
      <van-form ref="form">
        <van-field v-model="reqReceivAmount" readonly label="应收金额" />
        <van-field
          v-model="actReceivAmount"
          type="number"
          error
          border="true"
          right-icon="edit"
          label="实收金额"
        />
      </van-form>
    </van-dialog>
  </div>
</template>

<script>
import {
  ActionSheet,
  Dialog,
  Tab,
  Tabs,
  Field as VanField,
  Icon as VanIcon,
  Pagination as VanPagination,
  Loading,
  Overlay,
  Switch as VanSwitch,
  GridItem as VanGridItem,
  Grid as VanGrid,
  Stepper,
  Button as VanButton,
  Popup as VanPopup,
  Card,
} from "vant";
import {
  selectTableAreaList,
  selectTableList,
  getCurrentOrder,
  editOrder,
  selectOrderHistory,
  finishOrder,
  changeTable,
} from "@/api/tableAndArea";
import { selectGoodList, operatingData } from "@/api/merchant";
import { printOrder, modifyBizStatus, checkOutOrder } from "@/api/tableAndArea";
import Scroll from "@/components/scroll";
import { mapState } from "vuex";
import Cookies from "js-cookie";
import { merchantPlaceOrder } from "../../api/merchant";
export default {
  name: "MerchantBizMgr",
  components: {
    VanPagination,
    ActionSheet,
    VanButton,
    VanField,
    VanPopup,
    Overlay,
    VanSwitch,
    VanIcon,
    Loading,
    Scroll,
    VanGridItem,
    VanGrid,
    Card,
    Stepper,
    Tab,
    Tabs,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      activeNames: [],
      showHistory: false,
      currentIndex: 0,
      currentPage: 1,
      pageSize: 8,
      totalNum: 0,
      showDetail: false,
      bizStatus: false,
      isShowBil: false,
      show: false,
      list: [],
      table: [],
      loading: false,
      currentStatus: "orderUnDone",
      currentModel: "area",
      currentArea: null,
      delAreaLoading: false,
      formLoading: false,
      orders: [],
      currentOrder: null,
      currentOrderShow: false,
      orderLoading: false,
      showMenu: false,
      showChangeTab: false,
      addGoodLoding: false,
      currentTable: null,
      goods: [],
      tables: [],
      didUserAdded: false,
      foodName: "",
      tabName: "",
      oldTabId: "",
      activeTab: 0,
      totalTab: 0,
      todayRevenue: 0,
      addGoodDetail: [],
      addGoodDetailText: "",
      invoicing: false,
      actReceivAmount: 0,
      reqReceivAmount: 0,
      newCount: 0.0,
      isEdit: false,
    };
  },
  watch: {
    activeNames(now, before) {
      if (now.length > before.length) {
        const index = now[now.length - 1];
        this.selectTable(this.list[index]);
      }
    },
    currentIndex() {
      this.refreshTable();
    },
    $route(to) {
      if (to.name === "MerchantBizMgr") {
        this.refreshData();
      }
    },
  },
  computed: {
    undoneTotal() {
      let total = 0;
      let count = 0;
      if (!this.currentOrder) {
        return { total, count };
      }
      this.currentOrder.unpaidOrderDetails.forEach((value) => {
        total += value.total_amount * value.total_count;
        count += value.total_count;
      });
      total = total / 100;
      return { total, count };
    },
    doneTotal() {
      let total = 0;
      let count = 0;
      if (!this.currentOrder) {
        return { total, count };
      }
      this.currentOrder.orderDetails.forEach((value) => {
        total += value.total_amount * value.total_count;
        count += value.total_count;
      });
      total = total / 100;
      return { total, count };
    },
    searchedGoods() {
      return this.goods.filter(
        (value) => value.name.indexOf(this.foodName) > -1
      );
    },
    searchedTabs() {
      return this.tables.filter(
        (value) => value.name.indexOf(this.tabName) > -1
      );
    },
    ...mapState({
      merId: (state) => state.base.merchantId,
      res: (state) => state.base.currentRestaurant,
    }),
  },
  mounted() {
    this.bizStatus = this.res.status === 0;
    this.selectArea();
    this.getTabAndBizData();
  },
  methods: {
    refreshTable(force) {
      if (this.list.length === 0) {
        return;
      }
      const index = this.currentIndex > -1 ? this.currentIndex : 0;
      const areaId = this.list[index]?.id;
      if (this.list[index] && this.list[index].children && !force) {
        this.table = this.list[index].children;
      } else {
        if (!areaId) this.currentIndex = 0;
        this.loading = true;
        selectTableList(this.merId, this.list[index].id)
          .then((res) => {
            this.loading = false;
            this.table = res.data;
            this.$set(this.list[index], "children", res.data);
          })
          .catch(() => {
            this.loading = false;
            this.$toast.fail("获取桌台列表失败，请稍后再试");
          });
      }
    },
    selectArea() {
      this.loading = true;
      selectTableAreaList(this.merId)
        .then((res) => {
          this.list = res.data;
          this.loading = false;
          this.refreshTable(true);
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("获取区域失败，请稍后再试");
        });
    },
    selectTable(area) {
      this.loading = true;
      selectTableList(this.merId, area.id)
        .then((res) => {
          this.loading = false;
          if (!area.children) {
            this.$set(area, "children", res.data);
          } else {
            area.children = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("获取桌台失败，请稍后再试");
        });
    },
    checkCurrentOrder(table) {
      if (this.isShowBil || !table.is_finish) {
        return;
      }
      this.currentTable = table;
      this.loading = true;
      getCurrentOrder(this.merId, table.id)
        .then((res) => {
          const order = res.data.order;
          if (order.length) {
            this.totalNum = 0;
            this.orders = order;
            this.loading = false;
            this.showHistory = true;
          } else {
            this.currentOrder = res.data.order;
            this.loading = false;
            this.currentOrderShow = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("获取订单失败，请稍后再试");
        });
    },
    checkHistoryOrders(table) {
      this.loading = true;
      if (typeof table !== "object") {
        table = this.currentTable;
      } else {
        this.currentTable = table;
      }
      selectOrderHistory(this.merId, table.id, this.currentPage, this.pageSize)
        .then((res) => {
          this.orders = res.page.content;
          this.totalNum = res.page.totalElements;
          this.loading = false;
          this.showHistory = true;
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("获取历史订单失败，请稍后再试");
        });
    },
    checkHistoryOrder(order) {
      this.currentOrder = order;
      this.currentOrderShow = true;
    },
    changeGoodNum(good, type) {
      this.orderLoading = true;
      const backup = good.total_count;
      if (type === "plus") {
        good.total_count += 1;
      } else good.total_count -= 1;
      editOrder({ detail_id: good.id, ...good })
        .then(() => {
          this.orderLoading = false;
        })
        .catch(() => {
          good.total_count = backup;
          this.orderLoading = false;
          this.$toast.fail("请求失败，请稍后再试");
        });
    },
    finishOrder(id) {
      this.$dialog
        .confirm({
          title: "提醒",
          message: "确认是否关闭该订单",
        })
        .then(() => {
          this.orderLoading = true;
          finishOrder(id)
            .then(() => {
              this.orderLoading = false;
              this.currentOrderShow = false;
              if (this.showHistory) {
                //此时若历史订单页弹出  则证明是单人模式
                const index = this.orders.findIndex((value) => value.id === id);
                this.orders.splice(index, 1);
              }
              this.getTabAndBizData();
              this.selectArea();
              this.refreshTable();
            })
            .catch(() => {
              this.orderLoading = false;
              this.$toast.fail("关闭订单失败，请稍后再试");
            });
        });
    },
    printOrder(id) {
      printOrder(id)
        .then(() => {
          this.$toast.success("打印成功");
        })
        .catch(() => {
          this.orderLoading = false;
          this.$toast.fail("打印订单失败，请稍后再试");
        });
    },
    editBizstatus(status) {
      //营业/打烊
      this.$dialog
        .confirm({
          title: "提示",
          message: status == true ? "确认营业？" : "确认打烊？",
        })
        .then(() => {
          modifyBizStatus({
            mer_id: this.merId,
            status: status == true ? 0 : 1,
          })
            .then((res) => {
              this.bizStatus = status;
              Cookies.set("mch", JSON.stringify(res.data));
              this.$store.commit("base/SET_CURRENT_RESTAURANT", res.data);
            })
            .catch(() => {
              this.$toast.fail("更新失败，请稍后再试");
            });
        });
    },
    getTabAndBizData() {
      operatingData(this.merId)
        .then((res) => {
          this.activeTab = res.data.openCount;
          this.totalTab = res.data.totalCount;
          this.todayRevenue = res.data.todayAmount / 100;
        })
        .catch(() => {
          this.$toast.fail("查询失败，请稍后再试");
        });
    },
    showMenuPop() {
      this.showMenu = true;
      this.loading = true;
      selectGoodList(this.merId)
        .then((res) => {
          this.goods = res.data;
          for (let i = 0; i < this.goods.length; i++) {
            this.goods[i].count = 0;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("请求菜品失败，请稍后再试");
        });
    },
    showTabPop() {
      this.showChangeTab = true;
      this.loading = true;
      selectTableList(this.merId, "")
        .then((res) => {
          this.tables = res.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("获取桌台失败，请稍后再试");
        });
    },
    addGoodChangeNum(good, type) {
      this.addGoodLoding = true;
      var backCount = good.count;
      if (type === "plus") {
        good.count += 1;
      } else {
        good.count -= 1;
      }
      var flag = false;
      if (this.addGoodDetail.length > 0) {
        for (let i = 0; i < this.addGoodDetail.length; i++) {
          if (this.addGoodDetail[i].goods_id === good.id) {
            flag = true;
            if (good.count === 0) {
              this.addGoodDetail.splice(i, 1);
              this.addGoodDetailText = this.addGoodDetailText.replace(
                " [" + good.name + "x" + backCount + "]",
                ""
              );
            } else {
              this.addGoodDetail[i].total_count = good.count;
              this.addGoodDetailText = this.addGoodDetailText.replace(
                good.name + "x" + backCount,
                good.name + "x" + good.count
              );
            }
          }
        }
      }
      if (!flag) {
        this.addGoodDetail.push({
          goods_id: good.id,
          total_count: good.count,
        });
        this.addGoodDetailText += " [" + good.name + "x" + good.count + "]";
      }
      this.addGoodLoding = false;
    },
    confirmAddGood(type) {
      if (this.addGoodDetail.length > 0) {
        this.addGoodLoding = true;
        const param = {
          is_print: type,
          order_id: this.currentOrder.id,
          orderDetails: this.addGoodDetail,
        };
        merchantPlaceOrder(param)
          .then(() => {
            this.$toast.success("加菜成功");
            this.addGoodLoding = false;
            this.loading = false;
            this.didUserAdded = true;
            this.addGoodDetail = [];
            this.addGoodDetailText = "";
            this.menuClose();
          })
          .catch(() => {
            this.$toast.fail("加菜失败");
            this.addGoodLoding = false;
            this.loading = false;
          });
      } else {
        this.didUserAdded = false;
        this.addGoodDetail = [];
        this.addGoodDetailText = "";
        this.showMenu = false;
      }
    },
    cancelAddGood() {
      this.addGoodDetailText = "";
      this.addGoodDetail = [];
      this.showMenu = false;
    },
    menuClose() {
      if (this.didUserAdded) {
        this.loading = true;
        this.didUserAdded = false;
        getCurrentOrder(this.merId, this.currentTable.id)
          .then((res) => {
            this.currentOrder = res.data.order;
            this.showMenu = false;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
            this.$toast.fail("刷新订单失败，请稍后再试");
          });
      }
    },
    //更换桌台
    changeTab(tab) {
      changeTable({
        mer_id: this.merId,
        old_table_id: this.currentTable.id,
        new_table_id: tab.id,
      })
        .then(() => {
          this.getTabAndBizData();
          this.selectArea();
          this.refreshTable();
          this.showChangeTab = false;
          this.currentOrderShow = false;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
          this.$toast.fail("更换桌台失败，请稍后再试");
        });
    },
    showInvoice() {
      if (0 === this.undoneTotal.total) {
        this.$toast.fail("没有未支付订单！");
        return;
      }

      this.reqReceivAmount = this.undoneTotal.total;
      this.actReceivAmount = this.undoneTotal.total;
      this.invoicing = true;
    },
    //结账确认
    beforeChange(action, done) {
      if (action === "confirm") {
        checkOutOrder({
          order_id: this.currentOrder.id,
          amount: this.reqReceivAmount * 100,
          pay_amount: this.actReceivAmount * 100,
        })
          .then(() => {
            getCurrentOrder(this.merId, this.currentOrder.tab_id) //更新订单
              .then((res) => {
                this.currentOrder = res.data.order;
              })
              .catch(() => {
                this.$toast.fail("刷新订单失败，请稍后再试");
              });
            this.$toast.success("结账成功");
            done();
          })
          .catch(() => {
            this.$toast.fail("结账失败");
            done();
          });
      } else {
        this.invoicing = false;
        done();
      }
    },
    onChange(val) {
      this.newCount = val;
    },
    changeCount(good) {
      if (good.total_count != this.newCount) {
        good.total_count = this.newCount;
        //发起后台请求
        this.orderLoading = true;
        editOrder({ detail_id: good.id, ...good })
          .then(() => {
            this.orderLoading = false;
          })
          .catch(() => {
            this.orderLoading = false;
            this.$toast.fail("请求失败，请稍后再试");
          });
      }
    },
    refreshData() {
      this.getTabAndBizData();
      this.selectArea();
      this.refreshTable();
    },
  },
};
</script>

<style lang="less" scoped>
.table-area-operation {
  color: @primary-color;
  font-size: 0.7rem;
  padding-top: 0.4rem;
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.table-area {
  /deep/[class*="van-hairline"]::after {
    border: 1px solid #b7b7b7;
    border-radius: 10px;
  }
  /deep/.van-grid-item__content {
    background-color: rgb(245, 245, 245);
    color: #414545;
  }
  /deep/.van-collapse-item__content {
    padding-left: 0;
    padding-right: 0;
  }
  .table-grid {
    /deep/.van-grid-item__content {
      padding: 0;
    }
  }
}
/deep/ .van-button--mini {
  font-size: 0.5rem;
}
/deep/ .van-dialog {
  top: 50%;
}
.history-order-item {
  padding: 0.4rem;
  font-size: 0.6rem;
}
.my-table {
  border-collapse: separate;
  border-spacing: 10px 0.6rem;
  thead td {
    color: #768181;
    padding-bottom: 0.1rem;
  }
  tbody td {
    color: #6d6d6d;
    vertical-align: bottom;
    &:last-child {
      text-align: right;
    }
  }
}

.top-area {
  border-radius: 0.5rem;
  background: #3a8ffc;
  border: 0.04rem solid #3a8ffc;
}
.edit-area {
  flex-shrink: 0;
  padding: 0.3rem;
  margin: 0.5rem 0.5rem 0.5rem 0.5rem;
  border-radius: 0.5rem;
  background: -webkit-linear-gradient(
    right,
    #3a8ffc,
    #74b6ff
  ); /*forsafari5.1-6.0*/
  background: -o-linear-gradient(right, #3a8ffc, #74b6ff); /*Opera11.1-12.0*/
  background: -moz-linear-gradient(right, #3a8ffc, #74b6ff); /*firefox3.6-15*/
  background: linear-gradient(
    to right,
    #3a8ffc,
    #74b6ff
  ); /*标准语法，必须放在最后*/
}

.table-box {
  border-radius: 0.3rem;
  padding: 0.3rem;
  background-color: #fff;
  width: 2.5rem;
  height: 3.2rem;
  float: left;
  margin: 0.3rem 0 0.5rem 0.5rem;
  box-shadow: 0 0 0.1rem #9f9f9f;
}
.opt-btn {
  padding: 5px;
  z-index: 100;
}
.storage {
  overflow: hidden;
  width: 100%;
  padding: 0;
  .table-name {
    font-size: 0.45rem;
    color: #3d3d3d;
    word-break: break-all;
    text-align: center;
  }
}
.user-header {
  display: flex;
  padding: 0.16rem 0.16rem 0 0.08rem;
  width: 3.1rem;
  align-items: center;
  justify-content: center;
  img {
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 50%;
  }
}
.s-openning {
  border-radius: 1rem;
  background-color: #6ab963;
  font-size: 0.4rem;
  color: #f6eceb;
  width: 1.6rem;
  margin: 0.05rem 0 0 0.7rem;
  padding: 0.1rem 0 0.1rem 0.3rem;
}
.s-closing {
  border-radius: 1rem;
  background-color: #ee2e2d;
  font-size: 0.4rem;
  color: #f6eceb;
  width: 1.6rem;
  margin: 0.15rem 0 0 0.9rem;
  padding: 0.1rem 0 0.1rem 0.3rem;
}
.show-history {
  position: absolute;
  padding: 0.2rem;
  /*background: #9cc9f6;*/
  background: #f6f6f6;
  height: 1rem;
  width: 100%;
  bottom: 0rem;
}
.refresh {
  position: absolute;
  padding: 0.2rem;
  /*background: #9cc9f6;*/
  background: #f6f6f6;
  height: 1rem;
  width: 50%;
  bottom: 0rem;
  display: flex;
  justify-content: flex-end;
}
.history-order {
  background-color: @mch-color;
  border-radius: 10px;
  color: #fff;
  margin: 0 -8px 0 -8px;
  font-size: 0.5rem;
  padding: 4px 0;
  text-align: center;
}
.tab-number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.7rem;
  height: 0.7rem;
  background-color: #fff;
  border-radius: 50%;
  div {
    height: 100%;
    color: @mch-color;
    font-size: 0.6rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.t-head tr td {
  font-width: 900;
}

/deep/ .van-tabs__line {
  background-color: @mch-color;
  width: 3rem;
}
/deep/ .van-tab--active {
  color: @mch-color;
}
/deep/ .van-switch--on {
  background-color: #6ab963;
}
/deep/ .van-grid-item__content--center {
  border-radius: 0.4rem;
  background-color: #fff;
  box-shadow: 0 0 0.06rem @mch-color;
}
/deep/ .van-action-sheet__content {
  background-color: #f3f3f3;
  padding: 0.3rem 0.1rem 0 0.1rem;
}
</style>
