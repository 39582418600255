import request from "@/utils/request";
export function selectTableAreaList(merId) {
  return request({
    baseURL: "/SntWxMch",
    url: "/selectTableAreaList",
    method: "post",
    data: { merId },
  });
}
export function addTableArea(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/addTableArea",
    method: "post",
    data: data,
  });
}
export function editTableArea(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/editTableArea",
    method: "post",
    data: data,
  });
}
export function deleteTableArea(id) {
  return request({
    baseURL: "/SntWxMch",
    url: "/deleteTableArea",
    method: "post",
    data: { id },
  });
}
export function selectTableList(merId, area) {
  return request({
    baseURL: "/SntWxMch",
    url: "/selectTableList",
    method: "post",
    data: { merId, area },
  });
}
export function addTable(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/addTable",
    method: "post",
    data: data,
  });
}
export function editTable(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/editTable",
    method: "post",
    data: data,
  });
}
export function deleteTable(id) {
  return request({
    baseURL: "/SntWxMch",
    url: "/deleteTable",
    method: "post",
    data: { id },
  });
}
export function getCurrentOrder(merId, tableId) {
  return request({
    baseURL: "/SntWxMch",
    url: "/manageTable",
    method: "post",
    data: { merId, tableId },
  });
}
export function editOrder(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/editOrder",
    method: "post",
    data: data,
  });
}
export function finishOrder(order_id) {
  return request({
    baseURL: "/SntWxMch",
    url: "/finishOrder",
    method: "post",
    data: { order_id },
  });
}

/**
 * 打印订单
 * @param order_id
 * @returns {*}
 */
export function printOrder(order_id) {
  return request({
    baseURL: "/SntWxMch",
    url: "/printOrder",
    method: "post",
    data: { order_id },
  });
}

/**
 * 查询历史订单
 * @param merId
 * @param tableId
 * @param pageNumber
 * @param pageSize
 * @returns {*}
 */
export function selectOrderHistory(merId, tableId, pageNumber, pageSize) {
  return request({
    baseURL: "/SntWxMch",
    url: "/selectOrderHistory",
    method: "post",
    data: { merId, tableId, pageNumber, pageSize },
  });
}

/**
 * 修改营业状态
 * @param order_id
 * @returns {*}
 */
export function modifyBizStatus(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/openOrCloseMerchant",
    method: "post",
    data: data,
  });
}

/**
 * 更换桌台
 * @param order_id
 * @returns {*}
 */
export function changeTable(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/changeTable",
    method: "post",
    data: data,
  });
}

/**
 * 结账
 * @param order_id
 * @returns {*}
 */
export function checkOutOrder(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/checkOutOrder",
    method: "post",
    data: data,
  });
}

/**
 * 绑定二维码
 * @param data
 * @returns {*}
 */
export function bindQrcode(data) {
  return request({
    baseURL: "/SntWxMch",
    url: "/bindQrcode",
    method: "post",
    data: data,
  });
}
